exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-details-tsx": () => import("./../../../src/pages/order-details.tsx" /* webpackChunkName: "component---src-pages-order-details-tsx" */),
  "component---src-pages-order-fail-tsx": () => import("./../../../src/pages/order-fail.tsx" /* webpackChunkName: "component---src-pages-order-fail-tsx" */),
  "component---src-pages-order-successful-tsx": () => import("./../../../src/pages/order-successful.tsx" /* webpackChunkName: "component---src-pages-order-successful-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-reception-tsx": () => import("./../../../src/pages/reception.tsx" /* webpackChunkName: "component---src-pages-reception-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/product-details.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */)
}

