import React, { useState } from "react";
import { Picked } from "../templates/product-details";

export type CartProduct = {
  id: number;
  productId: number;
  title: string;
  price: number;
  image: object;
  sizes: Picked[];
  variant_title?: string;
};

export interface Context {
  products: CartProduct[] | undefined;
  changeProducts: (e: CartProduct[]) => void;
  isLoggedIn: boolean;
  changeIsLoggedIn: () => void;
}

export const myContext = React.createContext({});

const Provider = (props) => {
  const [products, setProducts] = useState<CartProduct[] | undefined>(
    undefined
  );
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  return (
    <myContext.Provider
      value={{
        products,
        changeProducts: (products: CartProduct[]) =>
          products && setProducts([...products]),
        isLoggedIn,
        changeIsLoggedIn: () => setIsLoggedIn(!isLoggedIn),
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};

export default ({ element }) => <Provider>{element}</Provider>;
